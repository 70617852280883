@import "../../assets/style/colors";
@import "../../assets/style/fonts";
@import "../../assets/style/extends";

.main-index {
  height: 100vh;
  &__wrapper {
    background: url("../../assets/img/pic/combine-harvester-working-wheat-field\ 2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }

  &__box {
    background-color: $accent;
    max-width: 708px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 6.25rem;
    box-sizing: border-box;
  }

  &__text {
    @extend %reg32;
    color: $white;
    margin-top: 3.125rem;
    max-width: 469px;
  }

  &__sub {
    @extend %reg24;
    color: $white;
    margin-top: 1.875rem;
  }

  @media (max-width: 1440px) {
    &__box {
      max-width: 50%;
    }
  }

  @media (max-width: 1000px) {
    &__logo {
      max-width: 195px;
    }

    &__box {
      padding: 0 3.125rem;
    }
    &__text {
      font-size: 1.5rem;
    }

    &__sub {
      font-size: 1.25rem;
    }
  }

  @media (max-width: 769px) {
    &__box {
      max-width: 70%;
    }
  }

  @media (max-width: 480px) {
    &__box {
      max-width: 100%;
      padding: 0 1.875rem;
    }
  }
}
