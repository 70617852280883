%h1 {
  font-family: "FSB";
  font-size: 4rem;
  line-height: 1.3;
}

%h2 {
  font-family: "FSB";
  font-size: 2.5rem;
  line-height: 1.3;
}

%h3 {
  font-family: "FSB";
  font-size: 2rem;
  line-height: 1.1;
}

%h4 {
  font-family: "FSB";
  font-size: 1.5rem;
  line-height: 1.3;
}

%h5 {
  font-family: "FSB";
  font-size: 1.25rem;
  line-height: 1.3;
}

%bold {
  font-family: "FSB";
  font-size: 0.875rem;
  line-height: 1.3;
}

%reg32 {
  font-family: "FSR";
  font-size: 2rem;
  line-height: 1.3;
}

%reg24 {
  font-family: "FSR";
  font-size: 1.5rem;
  line-height: 1.3;
}

%reg20 {
  font-family: "FSR";
  font-size: 1.25rem;
  line-height: 1.3;
}

%reg18 {
  font-family: "FSR";
  font-size: 1.125rem;
  line-height: 1.3;
}

%reg16 {
  font-family: "FSR";
  font-size: 1rem;
  line-height: 1.3;
}

%med24 {
  font-family: "FSM";
  font-size: 1.5rem;
  line-height: 1.3;
}

%med20 {
  font-family: "FSM";
  font-size: 1.25rem;
  line-height: 1.3;
}

%med18 {
  font-family: "FSM";
  font-size: 1.125rem;
  line-height: 1.3;
}

%med16 {
  font-family: "FSM";
  font-size: 1rem;
  line-height: 1.3;
}
