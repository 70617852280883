@import "../../assets/style/colors";
@import "../../assets/style/extends";

.sidebar {
  padding: 1.875rem 1.25rem;
  border-right: 1px solid $gray;

  .logo {
    display: inline-block;
    max-width: 237px;
  }

  &__wrapper {
    margin: 9.5rem 0;
    width: 100%;
    max-width: 275px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    max-width: 146px;
  }

  &__name {
    @extend %med24;
    margin-top: 0.5rem;
  }

  &__prof {
    @extend %reg18;
  }

  &-nav {
    margin-top: 3.125rem;
    border-inline-end: 0 !important;

    li {
      .ant-menu-item-icon {
        width: 24px;
        height: 24px;
      }
      a {
        @extend %reg20;
        display: block;
        color: $black;
        padding: 0.9rem 0;
        position: relative;
        transition: 0.3s ease-in;
      }
      &.ant-menu-item-selected {
        background-color: $gray;
        border-radius: 10px;
        color: $black;
      }
      &:hover {
        background-color: $gray;
        border-radius: 10px;
      }
    }
  }

  &__item {
    max-width: 275px;
    width: 100%;
    padding-left: 24px;
  }

  &__exit {
    @extend %reg20;
    text-align: right;
    border: 0;
    padding: 0;
    background-color: transparent;
    color: $black;
    width: 100%;
    max-width: 97px;
    position: relative;
    cursor: pointer;
    .exit {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: inline-block;
      background-image: url("../../assets/img/icon/exit.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 24px;
      height: 24px;
      margin-right: 0.625rem;
    }
  }

  @media (max-width: 1000px) {
    display: none;
  }
}
