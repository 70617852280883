@import "../../assets/style/colors";
@import "../../assets/style/extends";

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/img/pic/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  &__wrapper {
    background-color: $white;
    max-width: 544px;
    width: 100%;
    padding: 1.875rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
  }

  &__title {
    @extend %med24;
    margin: 1.25rem 0 3.125rem;
    color: $black;
  }

  .ant-form {
    max-width: 484px !important;
    width: 100% !important;
    label {
      @extend %reg16;
      align-items: center;
    }
    .login-form-forgot {
      @extend %reg16;
      color: $yellow;
    }
  }
  .ant-input {
    font-family: "FSR" !important;
    font-size: 1.125rem !important;
    padding: 1rem !important;
    color: $black !important;
    &-affix-wrapper {
      .ant-input {
        padding: 12px 4px !important;
      }
    }
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
  }

  &__button {
    @extend %reg18;
    color: $white;
    background: $accent;
    border: 1px solid $accent;
    width: 100%;
    padding: 0.9375rem;
    height: auto;
    box-shadow: 0 2px 0 rgba(0, 84, 28, 0.1) !important;
    &:hover {
      background: transparent !important;
      color: $accent !important;
    }
  }

  @media (max-width: 576px) {
    padding: 0 1.25rem;
    width: calc(100vw - 2.5rem);
    &__wrapper {
      padding: 1.875rem 1.25rem;
    }
  }
}
