@import "../../assets/style/colors";
@import "../../assets/style/extends";

.panel {
  &__title {
    margin-bottom: 1.875rem;
    justify-content: space-between;
    align-items: center;
    span {
      @extend %h2;
    }
  }
  &__link {
    @extend %reg20;
    transition: 0.3s ease-in;
    svg {
      margin-left: 0.625rem;
      stroke: $black;
      transition: 0.3s ease-in;
    }
    &:hover {
      color: $accent;
      svg {
        margin-left: 0.625rem;
        stroke: $accent;
      }
    }
  }
  &__box {
    &:first-child {
      margin-bottom: 1.875rem;
    }
  }

  @media (max-width: 1000px) {
    &__title {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      & > span {
        display: inline-block;
        margin-bottom: 1rem;
      }
    }
  }
}
