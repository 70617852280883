@import "../../assets/style/colors";

.header {
  padding: 1.25rem 1.875rem 1.25rem 0;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #f2f2f2;
  height: 84px;

  .logo {
    display: none;
  }

  .notification__button {
    background-color: transparent;
    border: 1px solid #f2f2f2;
    padding: 0.625rem;
    border-radius: 10px;
    cursor: pointer;
    font-size: 0;
  }

  @media (max-width: 1000px) {
    padding: 1.25rem 1.875rem;
    justify-content: space-between;
    .logo {
      display: block;
    }
  }
}
