@font-face {
  font-family: "FSB";
  src: url("../fonts/Firasansbold.eot");
  src: url("../fonts/Firasansbold.eot?#iefix")
  format("embedded-opentype"),
  url("../fonts/Firasansbold.woff2") format("woff2"),
  url("../fonts/Firasansbold.woff") format("woff"),
  url("../fonts/Firasansbold.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "FSM";
  src: url("../fonts/Firasansmedium.eot");
  src: url("../fonts/Firasansmedium.eot?#iefix")
  format("embedded-opentype"),
  url("../fonts/Firasansmedium.woff2") format("woff2"),
  url("../fonts/Firasansmedium.woff") format("woff"),
  url("../fonts/Firasansmedium.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "FSR";
  src: url("../fonts/Firasansregular.eot");
  src: url("../fonts/Firasansregular.eot?#iefix")
  format("embedded-opentype"),
  url("../fonts/Firasansregular.woff2") format("woff2"),
  url("../fonts/Firasansregular.woff") format("woff"),
  url("../fonts/Firasansregular.ttf") format("truetype");
  font-style: normal;
}
