@import "./colors";
@import "./fonts";
@import "./extends";

html,
body {
  height: 100%;
}

body {
  font-family: "FSR";
  font-size: 16px;
  color: $black;
  margin: 0;
  padding: 0;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  background-color: $white;
  main {
    flex-grow: 1;
  }

  footer {
    flex-shrink: 0;
  }
}

p {
  margin: 0;
}

a,
a:hover {
  color: $black;
  text-decoration: none;
  transition: 0.3s ease;
}

.img__fliud {
  width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ant-table-thead {
  background: $gray !important;
  border-radius: 5px;

  .ant-table-cell {
    @extend %reg18;
    color: #474747 !important;
    background: transparent !important;
    border: 0 !important;
    border-start-start-radius: 0;
    font-weight: 400 !important;
    padding: 0.625rem 0 !important;
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding-left: 1.25rem !important;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      padding-right: 1.25rem !important;
    }
  }
}

.ant-table-wrapper {
  table {
    border-spacing: 0 1.25rem !important;
  }
}

.ant-table-tbody {
  .ant-table-cell {
    @extend %reg18;
    padding: 1.25rem 0 !important;
    color: $black !important;
    border-bottom: 1px solid $gray !important;
    border-top: 1px solid $gray !important;

    &:first-child {
      padding-left: 1.25rem !important;
      border-left: 1px solid $gray !important;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      padding-right: 1.25rem !important;
      border-right: 1px solid $gray !important;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      text-align: right;
      a {
        @extend %reg18;
        color: $link !important;
      }
    }
  }
}

.ant-tag {
  @extend %med16;
  border-color: transparent;
  border-radius: 20px;
  padding: 0.3125rem 0.9375rem;
}

.panel {
  &__small {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 0.5rem;
    margin-bottom: 0.625rem;
  }

  &__box {
    margin: 3.125rem 1.25rem 0 1.25rem;
  }
}
