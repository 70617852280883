$accent: #00541c;
$white: #ffffff;
$yellow: #e0cd00;
$link: #4791ff;
$black: #292929;
$gray: #ededed;
$orange: #f08b31;
$lightOrange: #fff8ee;
$green: #80da76;
$lightGreen: #edffeb;
$red: #ff4a4a;
$lightRed: #ffeeee;
$error: #d8491b;
$shadow: rgba(0, 84, 28, 0.1);
