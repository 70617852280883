@import "../../assets/style/colors";
@import "../../assets/style/extends";

.offers {
  .ant-btn-primary {
    background: $accent;
    padding: 0.9375rem 1.875rem;
    height: auto;
    box-shadow: 0 2px 0 $shadow;
    border-radius: 5px;
    border: 1px solid $accent;
    span {
      @extend %reg18;
    }
    &:hover {
      background: transparent !important;
      color: $black !important;
    }
  }
}
